import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';

import { surveyDetailThunk } from 'store/actions/surveys';
import { surveyPropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';

import SurveyEventMessageHistory from './SurveyEventMessageHistory';
import SurveyAdminPageHeader from '../SurveyAdminPageHeader';

const surveyDate = (date) => (date ? moment(date).format('llll') : 'No date set');

export class SurveyAdminDistributionsPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    surveyDetailThunk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    notFound: PropTypes.bool.isRequired,
    survey: surveyPropTypes,
  };

  componentDidMount() {
    const { surveyId } = this.props.match.params;
    this.props.surveyDetailThunk(surveyId);
  }

  render() {
    const { isLoading, hasErrored, notFound, survey } = this.props;
    if (notFound) {
      return <Redirect to="/404" />;
    }
    if (hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Please try to reload the page or try again later.
        </Alert>
      );
    }
    if (isLoading) {
      return <Loading />;
    }
    return (
      <div>
        <div
          className="white-soft-box2 white-soft-box2--no-animate"
          style={{ paddingBottom: '16px' }}
        >
          <SurveyAdminPageHeader survey={survey}>Survey distributions</SurveyAdminPageHeader>
          <h2 className="page-header" style={{ marginBottom: '8px' }}>
            Availability
          </h2>
          <p>
            <strong>Opens:</strong> {surveyDate(survey.start)}
          </p>
          <p>
            <strong>Closes:</strong> {surveyDate(survey.end)}
          </p>
          <h2 className="page-header" style={{ marginBottom: '8px', marginTop: '20px' }}>
            Email history
          </h2>
          {survey.event ? (
            <SurveyEventMessageHistory event={survey.event.id} />
          ) : (
            <Alert type="warning" icon="fa-exclamation-triangle">
              This survey is not associated with a specific event. Email history is not available
              for surveys without an associated event.
            </Alert>
          )}
          <div className="well">
            <p>Emails are typically sent out on the following schedule:</p>
            <ul>
              <li>The first day the survey is available</li>
              <li>A reminder 3 days after the survey opens</li>
              <li>A reminder 7 days after the survey opens</li>
              <li>A final notice on the last day the survey is open</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { surveyId } = props.match.params;
  const survey = state.surveys.keys[surveyId];
  const isLoading = !survey || survey.isLoading || state.answers.keys.isLoading;
  const hasErrored = (survey && survey.hasErrored) || state.answers.keys.hasErrored;
  const notFound = !!(survey && survey.notFound);
  return {
    isLoading,
    hasErrored,
    notFound,
    survey,
  };
};

export default connect(
  mapStateToProps,
  { surveyDetailThunk }
)(SurveyAdminDistributionsPage);
