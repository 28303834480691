import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Alert, Loading, RelativeDate } from 'components';

import { EventMessages } from 'api';

function tagsTranslation(data) {
  if (!data) {
    return '';
  }
  let text = [];
  if (data['survey-start']) {
    text.push('Initial email');
  }
  if (data['survey-3days']) {
    text.push('3-day reminder');
  }
  if (data['survey-7days']) {
    text.push('7-day reminder');
  }
  if (data['survey-end']) {
    text.push('Final notice');
  }
  return text.join(', ');
}

class SurveyEventMessageHistory extends React.Component {
  static propTypes = {
    event: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  state = {
    isLoading: true,
    hasErrored: false,
    eventMessages: [],
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.fetch();
    }
  }

  async fetch() {
    if (!this.props.event) {
      return;
    }
    const tags = ['survey-start', 'survey-3days', 'survey-7days', 'survey-end'];
    let eventMessages;
    try {
      eventMessages = await EventMessages.list({ event: this.props.event, tags });
    } catch {
      this.setState({ isLoading: false, hasErrored: true });
      return;
    }
    eventMessages = eventMessages.results;
    eventMessages.forEach((m) => (m.sent_timestamp = moment(m.sent_timestamp)));
    eventMessages.sort((a, b) => b.sent_timestamp.diff(a.sent_timestamp));
    this.setState({ eventMessages, isLoading: false, hasErrored: false });
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Please try to reload the page or try again later.
        </Alert>
      );
    }
    if (this.state.isLoading) {
      return <Loading />;
    }
    if (this.state.eventMessages.length === 0) {
      return (
        <Alert type="info" icon="fa-clock-o">
          No emails have been sent out for this survey at this time.
        </Alert>
      );
    }
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date sent</th>
            <th>Recipient</th>
            <th>Email type</th>
          </tr>
        </thead>
        <tbody>
          {this.state.eventMessages.map((message) => (
            <tr key={message.id}>
              <td>
                <RelativeDate date={message.sent_timestamp} />
              </td>
              <td>{message.data && message.data.recipient_email}</td>
              <td>{tagsTranslation(message.data)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default SurveyEventMessageHistory;
